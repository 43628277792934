import { Thing } from 'schema-dts';

export const StructuredData = ({ data }: { data: Thing & object }) => {
  return (
    <script
      type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://schema.org',
          ...data,
        }),
      }}
    />
  );
};
